import React, { useEffect, useMemo, useState } from "react";
import { Dropdown, DropdownButton, Form } from "react-bootstrap";
import { graphql, useLazyLoadQuery } from "react-relay";
import { tagsQuery } from "./__generated__/tagsQuery.graphql";

const TagsDropdownQuery = graphql`
  query tagsQuery(
    $q: String
    $tags: [String!]
    $categories: [String!]
    $organizations: [String!]
    $topics: [String!]
  ) {
    tags(
      filter: {
        q: $q
        topics: $topics
        tags: $tags
        organizations: $organizations
        categories: $categories
      }
    ) {
      edges {
        node {
          name
          displayName
        }
      }
    }
  }
`;

interface TagsDropdownProps {
  searchParams: URLSearchParams;
  handleTagsChange: (selectedTags: string[]) => void;
  setSearchParams: (params: URLSearchParams) => void;
  selectedTags: string[];
  setSelectedTags: React.Dispatch<React.SetStateAction<string[]>>;
}

const TagsDropdown: React.FC<TagsDropdownProps> = ({
  searchParams,
  handleTagsChange,
  setSearchParams,
  setSelectedTags,
  selectedTags,
}) => {
  const [qInput, setQInput] = useState(searchParams.get("tags") || "");
  const [filteredTags, setFilteredTags] = useState<
    { name: string; displayName: string }[]
  >([]);

  const queryParameters = useMemo(
    () => ({
      q: searchParams.get("q") || "",
      topics: searchParams.getAll("topics"),
      organizations: searchParams.getAll("organizations"),
      categories: searchParams.getAll("categories"),
      tags: searchParams.getAll("tags"),
    }),
    [searchParams],
  );

  const { tags } = useLazyLoadQuery<tagsQuery>(
    TagsDropdownQuery,
    queryParameters,
  );

  useEffect(() => {
    if (qInput) {
      // Filter tags based on user input
      const filtered = tags.edges
        .filter((edge) =>
          edge.node.displayName.toLowerCase().includes(qInput.toLowerCase()),
        )
        .map((edge) => edge.node);
      setFilteredTags(filtered);
    } else {
      // Show all tags if no input is provided
      setFilteredTags(tags.edges.map((edge) => edge.node));
    }
  }, [qInput, tags]);

  const handleToggleTag = (tagName: string) => {
    setSelectedTags((prevTags) => {
      const updatedTags = prevTags.includes(tagName)
        ? prevTags.filter((tag) => tag !== tagName)
        : [...prevTags, tagName];
      handleTagsChange(updatedTags);
      return updatedTags;
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQInput(event.target.value);
  };

  useEffect(() => {
    try {
      const tagParams = searchParams.getAll("tags");
      if (tagParams.length > 0) {
        setSelectedTags(tagParams);
      }
    } catch (error) {
      console.error(
        "An error occurred when getting the tag parameters:",
        error,
      );
    }
  }, [searchParams]);

  return (
    <main>
      <div className="d-flex align-items-center">
        <DropdownButton id="search-dropdown" title="Tags" variant="success">
          <Form>
            <Dropdown.ItemText>
              <Form.Control
                type="text"
                placeholder="Search..."
                value={qInput}
                onChange={handleInputChange}
              />
            </Dropdown.ItemText>
          </Form>
          {filteredTags.length > 0 ? (
            filteredTags.slice(0, 10).map((tag) => (
              <Dropdown.Item
                key={tag.name}
                onClick={() => handleToggleTag(tag.name)}
                className={
                  selectedTags.includes(tag.name) ? "bg-success text-white" : ""
                }
              >
                {tag.displayName}
              </Dropdown.Item>
            ))
          ) : (
            <Dropdown.ItemText className="text-nowrap">
              No tags found
            </Dropdown.ItemText>
          )}
        </DropdownButton>
      </div>
      <div
        className="p-2"
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        {selectedTags.map((tag) => (
          <button
            key={tag}
            className="btn align-items-center p-2 rounded-pill d-flex btn-sm bg-secondary-subtle text-black ms-3 m-1 "
            onClick={() => handleToggleTag(tag)}
          >
            <i className="bi bi-x text-black"></i>
            <h6 className="mx-2 font-weight-bold  mb-0">{tag}</h6>
          </button>
        ))}
      </div>
    </main>
  );
};

export default TagsDropdown;
