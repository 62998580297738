import { Outlet } from "react-router-dom";
import { RelayEnvironmentProvider } from "react-relay/hooks";
import RelayEnvironment from "./RelayEnvironment";
import Loading from "./components/loading";
import React, { Suspense } from "react";
import Navigation from "./components/navigation";

const App: React.FC = () => {
  return (
    <RelayEnvironmentProvider environment={RelayEnvironment}>
      <Navigation />
      <Suspense fallback={<Loading />}>
        <Outlet />
      </Suspense>
    </RelayEnvironmentProvider>
  );
};

export default App;
