import Footer from "components/footer";
import React from "react";
import Container from "react-bootstrap/esm/Container";

const Terms = () => {
  return (
    <div className="d-flex flex-column min-100 ">
      <Container
        className="p-5 flex-grow-1 border-gray"
        style={{ border: "1px solid #000", borderRadius: "10px" }}
      >
        <h3 className="primary-color text-center fw-bold mb-5 ">
          Terms and Use
        </h3>
        <h5 className="fw-bold me-2">
          Effective Date: <span>25 Aug 2024</span>
        </h5>
        <p className="mx-3">
          By accessing and using the datasets.ai website (the "Site"), you agree
          to comply with and be bound by the following terms and conditions (the
          "Terms"). If you do not agree to these Terms, please do not use the
          Site.
        </p>
        <div>
          <h5 className="fw-bold">1. Use of the Site</h5>
          <p className="mx-3">
            You agree to use the Site only for lawful purposes and in accordance
            with these Terms. You must not use the Site in any way that may
            damage, disable, overburden, or impair the Site, or interfere with
            any other party’s use of the Site.
          </p>
        </div>
        <div>
          <h5 className="fw-bold">2. Intellectual Property</h5>
          <p className="mx-3">
            All content and materials on the Site, including but not limited to
            text, graphics, logos, and design elements, are the property of
            AntColony Inc. or its licensors, unless otherwise indicated.
          </p>
          <ul>
            <li>
              <p>
                <span className="fw-bold">
                  Open Data and Third-Party Content:{" "}
                </span>
                Some data and datasets on the Site are sourced from open data
                sites and other third-party providers. The intellectual property
                rights in such data and datasets belong to the respective third
                parties, and their use is subject to the terms and conditions
                set by those third parties.
              </p>
            </li>
            <li>
              <p>
                <span className="fw-bold">AntColony Inc. Content: </span>
                Some data and datasets on the Site are sourced from open data
                sites and other third-party providers. The intellectual property
                rights in such data and datasets belong to the respective third
                parties, and their use is subject to the terms and conditions
                set by those third parties.
              </p>
            </li>
            <p>
              You may not reproduce, distribute, or create derivative works from
              any content on the Site without our express written permission,
              except as permitted for third-party content in accordance with the
              terms set by the respective third parties.
            </p>
          </ul>
        </div>
        <div>
          <h5 className="fw-bold">3. Disclaimer of Warranties</h5>
          <p className="mx-3">
            The Site is provided on an "as is" and "as available" basis. We make
            no warranties, express or implied, regarding the Site’s
            availability, accuracy, or suitability for any particular purpose.
          </p>
        </div>
        <div>
          <h5 className="fw-bold">4. Limitation of Liability</h5>
          <p className="mx-3">
            To the fullest extent permitted by law, AntColony Inc. shall not be
            liable for any indirect, incidental, special, or consequential
            damages arising out of or in connection with your use of the Site.
          </p>
        </div>
        <div>
          <h5 className="fw-bold">5. Governing Law</h5>
          <p className="mx-3">
            These Terms are governed by and construed in accordance with the
            laws of Canada. Any disputes arising out of or related to these
            Terms or your use of the Site shall be resolved in the courts of
            Ontario.
          </p>
        </div>
        <div>
          <h5 className="fw-bold">6. Changes to These Terms</h5>
          <p className="mx-3">
            We may update these Terms from time to time. Changes will be posted
            on this page with an updated effective date. Your continued use of
            the Site following any changes constitutes your acceptance of the
            new Terms.
          </p>
        </div>
        <div>
          <h5 className="fw-bold">7. Contact Us</h5>
          <p className="mx-3">
            If you have any questions or concerns about these Terms, please
            contact us at:
          </p>
          <ul>
            <li>
              <p>
                <span className="fw-bold">DataSetsAI : </span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://datasets.ai/contact"
                  style={{ color: "#217821" }}
                >
                  Datasetsai Contact{" "}
                </a>
              </p>
            </li>
            <li>
              <p>
                <span className="fw-bold ">AntColony : </span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.antcolony.com/contact"
                  style={{ color: "#217821" }}
                >
                  AntColony Contact
                </a>
              </p>
            </li>
          </ul>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default Terms;
