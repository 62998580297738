import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../styles/footer.css";

const Footer: React.FC = () => {
  return (
    <Navbar
      bg="light"
      variant="light"
      className="py-2 mt-5 footer-shadow footer"
    >
      <Container className="d-flex flex-column  ">
        <Container className="d-flex justify-content-between mb-4 ">
          <Nav>
            <Nav.Link as={Link} to="/home" className="footer-btn">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/about" className="footer-btn">
              About
            </Nav.Link>
            <Nav.Link as={Link} to="/about" className="footer-btn">
              Contact
            </Nav.Link>
            <Nav.Link as={Link} to="/privacy" className="footer-btn">
              Privacy
            </Nav.Link>
            <Nav.Link as={Link} to="/terms" className="footer-btn">
              Terms
            </Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link
              className="animate-link"
              href="https://www.linkedin.com/company/datasets/?viewAsMember=true"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                style={{ width: "40px" }}
                src="https://www.edigitalagency.com.au/wp-content/uploads/new-linkedin-logo-white-black-png.png"
                alt="linkedin"
              />
            </Nav.Link>
            <Nav.Link
              className="animate-link"
              href="https://x.com/datasetsai"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                style={{ width: "40px" }}
                src="https://toppng.com/public/uploads/preview/twitter-x-icon-logo-116902890413xbfexhf8l.webp"
                alt="x icon for twitter"
              />
            </Nav.Link>
          </Nav>
        </Container>
        <Container className="text-center mt-auto">
          DataSetsAI © 2024. All Rights Reserved.
          <br />
          An{" "}
          <a
            href="https://www.antcolony.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-antcolony-link"
          >
            AntColony{" "}
          </a>
          product offering comprehensive access to open datasets globally.
        </Container>
      </Container>
    </Navbar>
  );
};

export default Footer;
