/**
 * @generated SignedSource<<3ea179ea735cb74f4ffd27641795f1e5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type listDatasetCountQuery$variables = {
  after?: string | null | undefined;
  before?: string | null | undefined;
  categories?: ReadonlyArray<string> | null | undefined;
  first?: number | null | undefined;
  last?: number | null | undefined;
  organizations?: ReadonlyArray<string> | null | undefined;
  q?: string | null | undefined;
  sourceKind?: number | null | undefined;
  tags?: ReadonlyArray<string> | null | undefined;
  topics?: ReadonlyArray<string> | null | undefined;
};
export type listDatasetCountQuery$data = {
  readonly datasetCount: number | null | undefined;
  readonly datasets: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: any;
        readonly name: string;
        readonly notes: string;
        readonly organization: {
          readonly title: string;
        };
        readonly sourceKind: number;
        readonly title: string;
      };
    }>;
    readonly pageInfo: {
      readonly endCursor: string | null | undefined;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null | undefined;
    };
  };
  readonly popularTags: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly displayName: string;
        readonly id: any;
        readonly name: string;
      };
    }>;
  };
};
export type listDatasetCountQuery = {
  response: listDatasetCountQuery$data;
  variables: listDatasetCountQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "categories"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizations"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "q"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sourceKind"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tags"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "topics"
},
v10 = {
  "kind": "Variable",
  "name": "categories",
  "variableName": "categories"
},
v11 = {
  "kind": "Variable",
  "name": "organizations",
  "variableName": "organizations"
},
v12 = {
  "kind": "Variable",
  "name": "q",
  "variableName": "q"
},
v13 = {
  "kind": "Variable",
  "name": "tags",
  "variableName": "tags"
},
v14 = {
  "kind": "Variable",
  "name": "topics",
  "variableName": "topics"
},
v15 = {
  "fields": [
    (v10/*: any*/),
    (v11/*: any*/),
    (v12/*: any*/),
    {
      "kind": "Variable",
      "name": "sourceKind",
      "variableName": "sourceKind"
    },
    (v13/*: any*/),
    (v14/*: any*/)
  ],
  "kind": "ObjectValue",
  "name": "filter"
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v19 = [
  {
    "alias": null,
    "args": [
      (v15/*: any*/)
    ],
    "kind": "ScalarField",
    "name": "datasetCount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "fields": [
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/)
        ],
        "kind": "ObjectValue",
        "name": "filter"
      }
    ],
    "concreteType": "TagConnection",
    "kind": "LinkedField",
    "name": "popularTags",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TagEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Tag",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v16/*: any*/),
              (v17/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "after",
        "variableName": "after"
      },
      {
        "kind": "Variable",
        "name": "before",
        "variableName": "before"
      },
      (v15/*: any*/),
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      }
    ],
    "concreteType": "DatasetConnection",
    "kind": "LinkedField",
    "name": "datasets",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasNextPage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasPreviousPage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startCursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endCursor",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DatasetEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Dataset",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v17/*: any*/),
              (v16/*: any*/),
              (v18/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "notes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sourceKind",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v18/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "listDatasetCountQuery",
    "selections": (v19/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v6/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v8/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v9/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Operation",
    "name": "listDatasetCountQuery",
    "selections": (v19/*: any*/)
  },
  "params": {
    "cacheID": "2cce66214f9ba1df7027636e75f2a788",
    "id": null,
    "metadata": {},
    "name": "listDatasetCountQuery",
    "operationKind": "query",
    "text": "query listDatasetCountQuery(\n  $q: String\n  $after: String\n  $before: String\n  $first: Int\n  $last: Int\n  $tags: [String!]\n  $categories: [String!]\n  $organizations: [String!]\n  $topics: [String!]\n  $sourceKind: Int\n) {\n  datasetCount(filter: {q: $q, topics: $topics, tags: $tags, organizations: $organizations, categories: $categories, sourceKind: $sourceKind})\n  popularTags(filter: {q: $q, topics: $topics, tags: $tags, organizations: $organizations, categories: $categories}) {\n    edges {\n      node {\n        id\n        name\n        displayName\n      }\n    }\n  }\n  datasets(filter: {q: $q, topics: $topics, tags: $tags, organizations: $organizations, categories: $categories, sourceKind: $sourceKind}, first: $first, last: $last, after: $after, before: $before) {\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      startCursor\n      endCursor\n    }\n    edges {\n      node {\n        name\n        id\n        title\n        notes\n        sourceKind\n        organization {\n          title\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1e60f74ca14470ae4f7678238876c1ca";

export default node;
