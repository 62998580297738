import React, { useEffect, useMemo, useState } from "react";
import { Dropdown, DropdownButton, Form } from "react-bootstrap";
import { graphql, useLazyLoadQuery } from "react-relay";
import { categoriesQuery } from "./__generated__/categoriesQuery.graphql";

const CategoriesDropdownQuery = graphql`
  query categoriesQuery(
    $q: String
    $tags: [String!]
    $categories: [String!]
    $organizations: [String!]
    $topics: [String!]
  ) {
    categories(
      filter: {
        q: $q
        topics: $topics
        tags: $tags
        organizations: $organizations
        categories: $categories
      }
    ) {
      edges {
        node {
          name
          displayName
        }
      }
    }
  }
`;

interface CategoriesDropdownProps {
  searchParams: URLSearchParams;
  handleCategoryChange: (name: string[]) => void;
  setSearchParams: (params: URLSearchParams) => void;
  selectedCategories: string[];
  setSelectedCategories: React.Dispatch<React.SetStateAction<string[]>>;
}

const CategoriesDropdown: React.FC<CategoriesDropdownProps> = ({
  searchParams,
  handleCategoryChange,
  setSearchParams,
  setSelectedCategories,
  selectedCategories,
}) => {
  const [qInput, setQInput] = useState<string>(
    searchParams.get("categories") || "",
  );
  const [filteredCategories, setFilteredCategories] = useState<
    { name: string; displayName: string }[]
  >([]);

  const queryParameters = useMemo(
    () => ({
      q: searchParams.get("q") || "",
      topics: searchParams.getAll("topics"),
      organizations: searchParams.getAll("organizations"),
      categories: searchParams.getAll("categories"),
      tags: searchParams.getAll("tags"),
    }),
    [searchParams],
  );

  const { categories } = useLazyLoadQuery<categoriesQuery>(
    CategoriesDropdownQuery,
    queryParameters,
  );

  useEffect(() => {
    if (qInput) {
      // Filter categories based on user input
      const filtered = categories.edges
        .filter((edge) =>
          edge.node.displayName.toLowerCase().includes(qInput.toLowerCase()),
        )
        .map((edge) => edge.node);
      setFilteredCategories(filtered);
    } else {
      // Show all categories if no input is provided
      setFilteredCategories(categories.edges.map((edge) => edge.node));
    }
  }, [qInput, categories]);

  const handleToggleCategory = (categoryName: string) => {
    setSelectedCategories((prevCategories) => {
      const updatedCategories = prevCategories.includes(categoryName)
        ? prevCategories.filter((category) => category !== categoryName)
        : [...prevCategories, categoryName];
      handleCategoryChange(updatedCategories);
      return updatedCategories;
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQInput(event.target.value);
  };

  useEffect(() => {
    try {
      const categoryParams = searchParams.getAll("categories");
      if (categoryParams.length > 0) {
        setSelectedCategories(categoryParams);
      }
    } catch (error) {
      console.error(
        "An error occurred when getting the category parameters:",
        error,
      );
    }
  }, [searchParams]);

  return (
    <main>
      <div className="d-flex align-items-center">
        <DropdownButton
          id="search-dropdown"
          title="Categories"
          variant="success"
        >
          <Form>
            <Dropdown.ItemText>
              <Form.Control
                type="text"
                placeholder="Search..."
                value={qInput}
                onChange={handleInputChange}
              />
            </Dropdown.ItemText>
          </Form>
          {filteredCategories.length > 0 ? (
            filteredCategories.slice(0, 10).map((category) => (
              <Dropdown.Item
                key={category.name}
                eventKey={category.name}
                onClick={() => handleToggleCategory(category.name)}
                className={
                  selectedCategories.includes(category.name)
                    ? "bg-success text-white"
                    : ""
                }
              >
                {category.displayName}
              </Dropdown.Item>
            ))
          ) : (
            <Dropdown.ItemText className="text-nowrap">
              No categories found
            </Dropdown.ItemText>
          )}
        </DropdownButton>
      </div>
      <div
        className="p-2"
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        {selectedCategories.map((category) => (
          <button
            key={category}
            className="btn align-items-center p-2 rounded-pill d-flex btn-sm bg-secondary-subtle text-black ms-3 m-1 "
            onClick={() => handleToggleCategory(category)}
          >
            <i className="bi bi-x text-black"></i>
            <h6 className="mx-2 font-weight-bold  mb-0">{category}</h6>
          </button>
        ))}
      </div>
    </main>
  );
};

export default CategoriesDropdown;
