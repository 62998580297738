/**
 * @generated SignedSource<<7c17c323c5761549ce85adab43461f19>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type detailDatasetsQuery$variables = {
  name: string;
};
export type detailDatasetsQuery$data = {
  readonly dataset: {
    readonly categories: ReadonlyArray<{
      readonly displayName: string;
      readonly id: any;
      readonly name: string;
    }>;
    readonly id: any;
    readonly notes: string;
    readonly resources: ReadonlyArray<{
      readonly description: string;
      readonly format: number;
      readonly id: any;
      readonly name: string;
      readonly url: string;
    }>;
    readonly sourceKind: number;
    readonly tags: ReadonlyArray<{
      readonly displayName: string;
      readonly id: any;
      readonly name: string;
    }>;
    readonly title: string;
    readonly topics: ReadonlyArray<{
      readonly displayName: string;
      readonly id: any;
      readonly name: string;
    }>;
    readonly url: string | null | undefined;
  };
};
export type detailDatasetsQuery = {
  response: detailDatasetsQuery$data;
  variables: detailDatasetsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v1/*: any*/),
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayName",
    "storageKey": null
  }
],
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      }
    ],
    "concreteType": "Dataset",
    "kind": "LinkedField",
    "name": "dataset",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "notes",
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sourceKind",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Category",
        "kind": "LinkedField",
        "name": "categories",
        "plural": true,
        "selections": (v4/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Tag",
        "kind": "LinkedField",
        "name": "tags",
        "plural": true,
        "selections": (v4/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Topic",
        "kind": "LinkedField",
        "name": "topics",
        "plural": true,
        "selections": (v4/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Resource",
        "kind": "LinkedField",
        "name": "resources",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "format",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "detailDatasetsQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "detailDatasetsQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "e69bf7fc30569d6be7bd7bdb21702074",
    "id": null,
    "metadata": {},
    "name": "detailDatasetsQuery",
    "operationKind": "query",
    "text": "query detailDatasetsQuery(\n  $name: String!\n) {\n  dataset(name: $name) {\n    id\n    title\n    notes\n    url\n    sourceKind\n    categories {\n      id\n      name\n      displayName\n    }\n    tags {\n      id\n      name\n      displayName\n    }\n    topics {\n      id\n      name\n      displayName\n    }\n    resources {\n      id\n      name\n      format\n      url\n      description\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7481052a084723f9ba46c865e5a0d334";

export default node;
