import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ErrorPage from "./error-page";
import DatasetList from "./datasets/list";
import DatasetDetail from "./datasets/detail";
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import { Privacy } from "components/details/privacy";
import Terms from "components/details/terms";
import { SnackbarProvider, useSnackbar } from "notistack";

const NotFound = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const hasNotified = useRef(false);

  useEffect(() => {
    navigate("/", { replace: true });
    if (!hasNotified.current) {
      enqueueSnackbar(
        "You have been redirected to the home page because the page you were trying to access does not exist.",
        { variant: "success" },
      );
      hasNotified.current = true;
    }
  }, [navigate, enqueueSnackbar]);

  return null;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <DatasetList />,
      },
      {
        path: "datasets/:name",
        element: <DatasetDetail />,
      },
      {
        path: "/privacy",
        element: <Privacy />,
      },
      {
        path: "/terms",
        element: <Terms />,
      },
      {
        path: "/",
        element: <NotFound />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <SnackbarProvider maxSnack={3}>
      <RouterProvider router={router} />
    </SnackbarProvider>
  </React.StrictMode>,
);

reportWebVitals();
reportWebVitals();
