import React, { useEffect, useMemo, useState } from "react";
import { Dropdown, DropdownButton, Form } from "react-bootstrap";
import { graphql, useLazyLoadQuery } from "react-relay";
import { organizationsQuery } from "./__generated__/organizationsQuery.graphql";

const OrganizationDropdownQuery = graphql`
  query organizationsQuery(
    $q: String
    $tags: [String!]
    $categories: [String!]
    $organizations: [String!]
    $topics: [String!]
  ) {
    organizations(
      filter: {
        q: $q
        topics: $topics
        tags: $tags
        organizations: $organizations
        categories: $categories
      }
    ) {
      edges {
        node {
          name
          title
        }
      }
    }
  }
`;

interface OrganizationDropdownProps {
  searchParams: URLSearchParams;
  handleOrganizationChange: (selectedOrganizations: string[]) => void;
  setSearchParams: (params: URLSearchParams) => void;
  selectedOrganizations: string[];
  setSelectedOrganizations: React.Dispatch<React.SetStateAction<string[]>>;
}

const OrganizationDropdown: React.FC<OrganizationDropdownProps> = ({
  searchParams,
  handleOrganizationChange,
  setSearchParams,
  setSelectedOrganizations,
  selectedOrganizations,
}) => {
  const [qInput, setQInput] = useState<string>(
    searchParams.get("organizations") || "",
  );
  const [filteredOrganizations, setFilteredOrganizations] = useState<
    { name: string; title: string }[]
  >([]);

  const queryParameters = useMemo(
    () => ({
      q: searchParams.get("q") || "",
      topics: searchParams.getAll("topics"),
      organizations: searchParams.getAll("organizations"),
      categories: searchParams.getAll("categories"),
      tags: searchParams.getAll("tags"),
    }),
    [searchParams],
  );

  const { organizations } = useLazyLoadQuery<organizationsQuery>(
    OrganizationDropdownQuery,
    queryParameters,
  );

  useEffect(() => {
    if (qInput) {
      // Filter organizations based on user input
      const filtered = organizations.edges
        .filter((edge) =>
          edge.node.title.toLowerCase().includes(qInput.toLowerCase()),
        )
        .map((edge) => edge.node);
      setFilteredOrganizations(filtered);
    } else {
      // Show all organizations if no input is provided
      setFilteredOrganizations(organizations.edges.map((edge) => edge.node));
    }
  }, [qInput, organizations]);

  const handleToggleOrganization = (organizationName: string) => {
    setSelectedOrganizations((prevOrganizations) => {
      const updatedOrganizations = prevOrganizations.includes(organizationName)
        ? prevOrganizations.filter(
            (organization) => organization !== organizationName,
          )
        : [...prevOrganizations, organizationName];
      handleOrganizationChange(updatedOrganizations);
      return updatedOrganizations;
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQInput(event.target.value);
  };

  useEffect(() => {
    try {
      const organizationParams = searchParams.getAll("organizations");
      if (organizationParams.length > 0) {
        setSelectedOrganizations(organizationParams);
      }
    } catch (error) {
      console.error(
        "An error occurred when getting the organization parameters:",
        error,
      );
    }
  }, [searchParams]);

  return (
    <main>
      <div className="d-flex align-items-center">
        <DropdownButton
          id="search-dropdown"
          title="Organization"
          variant="success"
        >
          <Form>
            <Dropdown.ItemText>
              <Form.Control
                type="text"
                placeholder="Search..."
                value={qInput}
                onChange={handleInputChange}
              />
            </Dropdown.ItemText>
          </Form>
          {filteredOrganizations.length > 0 ? (
            filteredOrganizations.slice(0, 10).map((organization) => (
              <Dropdown.Item
                key={organization.name}
                eventKey={organization.name}
                onClick={() => handleToggleOrganization(organization.name)}
                className={
                  selectedOrganizations.includes(organization.name)
                    ? "bg-success text-white"
                    : ""
                }
              >
                {organization.title}
              </Dropdown.Item>
            ))
          ) : (
            <Dropdown.ItemText className="text-nowrap">
              No organizations found
            </Dropdown.ItemText>
          )}
        </DropdownButton>
      </div>
      <div
        className="p-2"
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        {selectedOrganizations.map((organization) => (
          <button
            key={organization}
            className="btn align-items-center p-2 rounded-pill d-flex btn-sm bg-secondary-subtle text-black ms-3 m-1 "
            onClick={() => handleToggleOrganization(organization)}
          >
            <i className="bi bi-x text-black"></i>
            <h6 className="mx-2 font-weight-bold  mb-0">{organization}</h6>
          </button>
        ))}
      </div>
    </main>
  );
};

export default OrganizationDropdown;
