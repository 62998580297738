import React, { Suspense, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  ListGroup,
  Row,
  Stack,
} from "react-bootstrap";
import { graphql, usePreloadedQuery, useQueryLoader } from "react-relay";
import { Link, useSearchParams } from "react-router-dom";
import { SourceKindLabels } from "../choices";
import Footer from "../components/footer";
import SearchFilter from "../components/searchfilter";
import "styles/filter.css";
import "styles/list.css";
import "../index.css";

const DatasetCount = graphql`
  query listDatasetCountQuery(
    $q: String
    $after: String
    $before: String
    $first: Int
    $last: Int
    $tags: [String!]
    $categories: [String!]
    $organizations: [String!]
    $topics: [String!]
    $sourceKind: Int
  ) {
    datasetCount(
      filter: {
        q: $q
        topics: $topics
        tags: $tags
        organizations: $organizations
        categories: $categories
        sourceKind: $sourceKind
      }
    )
    popularTags(
      filter: {
        q: $q
        topics: $topics
        tags: $tags
        organizations: $organizations
        categories: $categories
      }
    ) {
      edges {
        node {
          id
          name
          displayName
        }
      }
    }
    datasets(
      filter: {
        q: $q
        topics: $topics
        tags: $tags
        organizations: $organizations
        categories: $categories
        sourceKind: $sourceKind
      }
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          name
          id
          title
          notes
          sourceKind
          organization {
            title
          }
        }
      }
    }
  }
`;

function DatasetList() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [queryReference, loadQuery] = useQueryLoader(DatasetCount);
  const [qInput, setQInput] = useState(searchParams.get("q") || "");
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(15);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    let sourceKindValue = searchParams.get("sourceKind");
    let sourceKind = sourceKindValue ? parseInt(sourceKindValue) : null;

    let params: { [key: string]: string | number | null } = {};
    searchParams.forEach((value: string, key: string) => {
      params[key] = value;
    });
    params["sourceKind"] = sourceKind;
    params["first"] = resultsPerPage;
    loadQuery(params);
  }, [loadQuery, searchParams, resultsPerPage]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSearchParams((prev: URLSearchParams): URLSearchParams => {
      prev.set("q", qInput);
      return prev;
    });
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <Container
        className="flex-grow-1"
        style={{ fontFamily: "'Istok Web', sans-serif" }}
      >
        <Row className="mb-3">
          <Col>
            <Form
              onSubmit={handleSubmit}
              className="w-100 d-flex align-items-center m-3"
            >
              <Row className="align-items-center w-100">
                <Col xs={12} sm={8} className="mb-3 mb-sm-0">
                  <div className="d-flex align-items-center p-1 rounded-pill border min-w-200 shadow-filter">
                    <Form.Control
                      type="text"
                      placeholder="Search..."
                      value={qInput}
                      className="p-1 m-1 border-0 shadow-none rounded-pill"
                      onChange={(e) => setQInput(e.target.value)}
                    />
                    <Button
                      variant="light"
                      onClick={handleShowModal}
                      className="mx-1 h-25 border-light rounded-pill min-w-40 shadow-filter filter-btn-icon"
                    >
                      <img
                        src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ficon-library.com%2Fimages%2Ffilter-png-icon%2Ffilter-png-icon-18.jpg&f=1&nofb=1&ipt=25aa448dab03bc215f8da299ba1ecc11b0f4cadf528342bdb861e821274d2e06&ipo=images"
                        alt="Filter"
                        style={{ width: "30px" }}
                      />
                    </Button>
                  </div>
                </Col>
                <Col xs={6} sm={2} className="mb-sm-0">
                  <Button type="submit" variant="light" className="filter-btn">
                    Search
                  </Button>
                </Col>
                <Col xs={6} sm={2}>
                  <Link to={"/"}>
                    <Button type="reset" variant="light" className="filter-btn">
                      Reset
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>

        {!queryReference && <div>Loading...</div>}
        {queryReference && (
          <QueryResult
            queryReference={queryReference}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            showModal={showModal}
            handleCloseModal={handleCloseModal}
            handleSubmit={handleSubmit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            resultsPerPage={resultsPerPage}
          />
        )}
        <SearchFilter
          setSearchParams={setSearchParams}
          handleCloseModal={handleCloseModal}
          searchParams={searchParams}
          showModal={showModal}
          qInput={qInput}
          setQInput={setQInput}
          handleSubmit={handleSubmit}
        />
      </Container>
      <Footer />
    </div>
  );
}

interface QueryResultProps {
  queryReference: any;
  setSearchParams: (params: URLSearchParams) => void;
  searchParams: URLSearchParams;
  showModal: boolean;
  handleCloseModal: () => void;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  resultsPerPage: number;
}
interface PageInfo {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
  endCursor: string;
}

type QueryResponse = {
  datasetCount: number;
  datasets: {
    edges: Array<{
      node: {
        name: string;
        id: string;
        title: string;
        notes: string;
        sourceKind: string;
        organization: {
          title: string;
        };
      };
    }>;
    pageInfo: PageInfo;
  };
  popularTags: {
    edges: Array<{
      node: {
        id: string;
        name: string;
        displayName: string;
      };
    }>;
  };
};
const QueryResult: React.FC<QueryResultProps> = ({
  queryReference,
  currentPage,
  setCurrentPage,
  setSearchParams,
  searchParams,
  resultsPerPage,
}: QueryResultProps) => {
  const data = usePreloadedQuery(DatasetCount, queryReference) as QueryResponse;

  const { datasetCount, datasets, popularTags } = data;

  const handlePageClick = (page: number) => {
    setCurrentPage(page);
    const newSearchParams = new URLSearchParams(searchParams.toString());
    if (page > currentPage) {
      newSearchParams.set("after", datasets.pageInfo.endCursor);
      newSearchParams.delete("before");
    } else if (page < currentPage) {
      newSearchParams.set("before", datasets.pageInfo.startCursor);
      newSearchParams.delete("after");
    }
    newSearchParams.set("first", resultsPerPage.toString());
    setSearchParams(newSearchParams);
  };

  const handleTagsChange = (selectedTopic: string) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.set("tag", selectedTopic);
    setSearchParams(newSearchParams);
  };

  return (
    <Suspense fallback={<div>Loading</div>}>
      <Row className="mb-4">
        {popularTags && (
          <Stack
            direction="horizontal"
            className="d-flex mt-3 align-items-center flex-wrap"
            gap={2}
          >
            <label className="popular-filter mb-5 me-2 ">Popular Tags: </label>
            {popularTags.edges.map(
              (edge: {
                node: { id: string; displayName: string; name: string };
              }) => (
                <Button
                  variant="light"
                  key={edge.node.id}
                  onClick={() => handleTagsChange(edge.node.name)}
                  className="d-flex align-items-center justify-content-center border-0 p-0 m-0 me-1 mb-5 popular-btn"
                >
                  <label className="fw-bold p-2 rounded-3 border-0 ">
                    {edge.node.displayName || edge.node.name}
                  </label>
                </Button>
              ),
            )}
          </Stack>
        )}
        <h1 className="popular-filter">
          {datasetCount} datasets are available
        </h1>
      </Row>
      <Row>
        <Col>
          <Card.Body>
            <ListGroup>
              <Card border="light" className="mb-4 border-0">
                <Card.Title className="popular-filter">
                  Popular Datasets
                </Card.Title>
              </Card>
              {datasets.edges.map((edge: any) => (
                <ListGroup.Item
                  key={edge.node.name}
                  className="mb-4  list-filter"
                >
                  <Link
                    to={`/datasets/${edge.node.name}`}
                    className="text-decoration-none border-none"
                  >
                    <h4 className="fs-5 fw-semibold list-title">
                      {edge.node.title}
                    </h4>
                    <h4 className="fs-5 fw-medium gap-1 gray-color">
                      <label>{edge.node.organization.title}</label>
                      <Button
                        variant="light"
                        className="h-10 p-1 border-0 rounded-pill ms-2 justify-content-center align-items-center source-kind-btn "
                      >
                        <label className="fw-semibold rounded-pill border-0">
                          {SourceKindLabels[edge.node.sourceKind]}
                        </label>
                      </Button>
                    </h4>
                    <p
                      className="fs-16 text-muted mb-0"
                      style={{
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {edge.node.notes}
                    </p>
                  </Link>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card.Body>
        </Col>
      </Row>
      <Container className="d-flex justify-content-center align-items-center m-3">
        <Button
          variant="light"
          disabled={currentPage === 1}
          onClick={() => handlePageClick(currentPage - 1)}
          className="border-0 mx-3 fs-6 next-prev-btn"
        >
          Previous
        </Button>
        <Button
          variant="light"
          disabled={!datasets.pageInfo.hasNextPage}
          onClick={() => handlePageClick(currentPage + 1)}
          className="border-0 fs-6 next-prev-btn"
        >
          Next
        </Button>
      </Container>
    </Suspense>
  );
};

export default DatasetList;
