import React, { useEffect, useMemo, useState } from "react";
import { Dropdown, DropdownButton, Form } from "react-bootstrap";
import { graphql, useLazyLoadQuery } from "react-relay";
import { topicsQuery } from "./__generated__/topicsQuery.graphql";

const TopicsDropdownQuery = graphql`
  query topicsQuery(
    $q: String
    $tags: [String!]
    $categories: [String!]
    $organizations: [String!]
    $topics: [String!]
  ) {
    topics(
      filter: {
        q: $q
        topics: $topics
        tags: $tags
        organizations: $organizations
        categories: $categories
      }
    ) {
      edges {
        node {
          name
          displayName
        }
      }
    }
  }
`;

interface TopicsProps {
  searchParams: URLSearchParams;
  handleTopicChange: (selectedTopics: string[]) => void;
  setSearchParams: (params: URLSearchParams) => void;
  selectedTopics: string[];
  setSelectedTopics: React.Dispatch<React.SetStateAction<string[]>>;
}

const Topics: React.FC<TopicsProps> = ({
  searchParams,
  handleTopicChange,
  setSearchParams,
  setSelectedTopics,
  selectedTopics,
}) => {
  const [qInput, setQInput] = useState<string>(
    searchParams.get("topics") || "",
  );
  const [filteredTopics, setFilteredTopics] = useState<
    { name: string; displayName: string }[]
  >([]);

  const queryParameters = useMemo(
    () => ({
      q: searchParams.get("q") || "",
      topics: searchParams.getAll("topics"),
      organizations: searchParams.getAll("organizations"),
      categories: searchParams.getAll("categories"),
      tags: searchParams.getAll("tags"),
    }),
    [searchParams],
  );

  const { topics } = useLazyLoadQuery<topicsQuery>(
    TopicsDropdownQuery,
    queryParameters,
  );

  useEffect(() => {
    if (qInput) {
      const filtered = topics.edges
        .filter((edge) =>
          edge.node.displayName.toLowerCase().includes(qInput.toLowerCase()),
        )
        .map((edge) => edge.node);
      setFilteredTopics(filtered);
    } else {
      setFilteredTopics(topics.edges.map((edge) => edge.node));
    }
  }, [qInput, topics]);

  const handleToggleTopic = (topicName: string) => {
    setSelectedTopics((prevTopics) => {
      const updatedTopics = prevTopics.includes(topicName)
        ? prevTopics.filter((topic) => topic !== topicName)
        : [...prevTopics, topicName];
      handleTopicChange(updatedTopics);
      return updatedTopics;
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQInput(event.target.value);
  };

  useEffect(() => {
    try {
      const topicParams = searchParams.getAll("topics");
      if (topicParams.length > 0) {
        setSelectedTopics(topicParams);
      }
    } catch (error) {
      console.error(
        "An error occurred when getting the topic parameters:",
        error,
      );
    }
  }, [searchParams]);

  return (
    <main>
      <div className="d-flex align-items-center">
        <DropdownButton id="search-dropdown" title="Topics" variant="success">
          <Form>
            <Dropdown.ItemText>
              <Form.Control
                type="text"
                placeholder="Search..."
                value={qInput}
                onChange={handleInputChange}
              />
            </Dropdown.ItemText>
          </Form>
          {filteredTopics.length > 0 ? (
            filteredTopics.slice(0, 10).map((topic) => (
              <Dropdown.Item
                key={topic.name}
                eventKey={topic.name}
                onClick={() => handleToggleTopic(topic.name)}
                className={
                  selectedTopics.includes(topic.name)
                    ? "bg-success text-white"
                    : ""
                }
              >
                {topic.displayName}
              </Dropdown.Item>
            ))
          ) : (
            <Dropdown.ItemText className="text-nowrap">
              No topics found
            </Dropdown.ItemText>
          )}
        </DropdownButton>
      </div>
      <div
        className="p-2"
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        {selectedTopics.map((topic) => (
          <button
            key={topic}
            className="btn align-items-center p-2 rounded-pill d-flex btn-sm bg-secondary-subtle text-black ms-3 m-1"
            onClick={() => handleToggleTopic(topic)}
          >
            <i className="bi bi-x text-black"></i>
            <h6 className="mx-2 font-weight-bold mb-0">{topic}</h6>
          </button>
        ))}
      </div>
    </main>
  );
};

export default Topics;
