// RelayEnvironment.js

import {
  Environment,
  Network,
  RecordSource,
  Store,
  GraphQLResponse,
} from "relay-runtime";

const relayApiUrl: string =
  process.env.REACT_APP_RELAY_API_URL || "http://localhost:8000/graphql";

async function fetchRelay(
  params: any,
  variables: any
): Promise<GraphQLResponse> {
  // Adjust the URL and headers according to your GraphQL server setup
  const response = await fetch(relayApiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: params.text,
      variables,
    }),
  });

  return await response.json();
}

const relayEnvironment: Environment = new Environment({
  network: Network.create(fetchRelay),
  store: new Store(new RecordSource()),
});

export default relayEnvironment;
