/**
 * @generated SignedSource<<bc288153a2ad612a6a6f5505e96f9d69>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type organizationsQuery$variables = {
  categories?: ReadonlyArray<string> | null | undefined;
  organizations?: ReadonlyArray<string> | null | undefined;
  q?: string | null | undefined;
  tags?: ReadonlyArray<string> | null | undefined;
  topics?: ReadonlyArray<string> | null | undefined;
};
export type organizationsQuery$data = {
  readonly organizations: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name: string;
        readonly title: string;
      };
    }>;
  };
};
export type organizationsQuery = {
  response: organizationsQuery$data;
  variables: organizationsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "categories"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizations"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "q"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tags"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "topics"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "categories",
            "variableName": "categories"
          },
          {
            "kind": "Variable",
            "name": "organizations",
            "variableName": "organizations"
          },
          {
            "kind": "Variable",
            "name": "q",
            "variableName": "q"
          },
          {
            "kind": "Variable",
            "name": "tags",
            "variableName": "tags"
          },
          {
            "kind": "Variable",
            "name": "topics",
            "variableName": "topics"
          }
        ],
        "kind": "ObjectValue",
        "name": "filter"
      }
    ],
    "concreteType": "OrganizationConnection",
    "kind": "LinkedField",
    "name": "organizations",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OrganizationEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "organizationsQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "organizationsQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "a810f79b0ab25069fcc48c0b01736463",
    "id": null,
    "metadata": {},
    "name": "organizationsQuery",
    "operationKind": "query",
    "text": "query organizationsQuery(\n  $q: String\n  $tags: [String!]\n  $categories: [String!]\n  $organizations: [String!]\n  $topics: [String!]\n) {\n  organizations(filter: {q: $q, topics: $topics, tags: $tags, organizations: $organizations, categories: $categories}) {\n    edges {\n      node {\n        name\n        title\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "34282a447e2299bdcd210498c39f4c31";

export default node;
