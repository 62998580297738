import React from "react";
import Header from "./header";
import Footer from "./footer";

const Navigation: React.FC = () => {
  return (
    <div className="main-wrapper">
      <Header />
      {/* <Footer /> */}
    </div>
  );
};

export default Navigation;
