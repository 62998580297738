import React from "react";
import { Container } from "react-bootstrap";
import "../../index.css";
import Footer from "components/footer";

export const Privacy = () => {
  return (
    <div className="d-flex flex-column min-100 ">
      <Container
        className="p-5 flex-grow-1 border-gray"
        style={{ border: "1px solid #000", borderRadius: "10px" }}
      >
        <h3 className="primary-color text-center fw-bold mb-5 ">
          Privacy Policy
        </h3>
        <h5 className="fw-bold me-2">
          Effective Date: <span>25 Aug 2024</span>
        </h5>
        <p className="mx-3">
          Welcome to datasets.ai, a service of AntColony Inc. ("we," "us," or
          "our"). We value your privacy and are committed to protecting your
          personal information. This Privacy Policy explains how we collect,
          use, and share information when you visit our website and use our
          services. By using our website, you agree to the practices described
          in this policy.
        </p>
        <div>
          <h5 className="fw-bold">1. Information We Collect</h5>
          <p className="mx-3">
            We do not collect personal information such as names, addresses, or
            passwords. However, we collect the following types of data:
          </p>
          <ul>
            <li>
              <div>
                <p className="">
                  <span className="fw-bold">
                    Cookies and Tracking Technologies:{" "}
                  </span>
                  We use cookies and similar tracking technologies to track user
                  behavior and improve our website. Cookies are small files
                  stored on your device that help us understand how you interact
                  with our site.
                </p>
              </div>
            </li>
            <li>
              <div>
                <p className="">
                  <span className="fw-bold">Usage Data: </span>
                  We collect information about how you use our website,
                  including your IP address, browser type, pages visited, and
                  the date and time of your visit.
                </p>
              </div>
            </li>
          </ul>
        </div>
        <div>
          <h5 className="fw-bold">2. How We Use Your Information</h5>
          <p className="mx-3">We use the collected data to:</p>
          <ul>
            <li>
              <p>
                We use cookies and similar tracking technologies to track user
                behavior and improve our website. Cookies are small files stored
                on your device that help us understand how you interact with our
                site.
              </p>
            </li>
            <li>
              <p>
                We collect information about how you use our website, including
                your IP address, browser type, pages visited, and the date and
                time of your visit.
              </p>
            </li>
            <li>
              <p>Monitor and prevent fraudulent activities.</p>
            </li>
          </ul>
        </div>
        <div>
          <h5 className="fw-bold">3. Third-Party Tools</h5>
          <p className="mx-3">
            We use third-party tools and services, including Google Analytics,
            to improve your experience. These third-party services may have
            their own privacy policies that govern how they handle data.
          </p>
        </div>
        <div>
          <h5 className="fw-bold">4. Data Security</h5>
          <p className="mx-3">
            We implement reasonable security measures to protect your data from
            unauthorized access, alteration, disclosure, or destruction.
            However, no method of transmission over the Internet or electronic
            storage is completely secure, and we cannot guarantee absolute
            security.
          </p>
        </div>
        <div>
          <h5 className="fw-bold">5. User Rights</h5>
          <p className="mx-3">
            Depending on your jurisdiction, you may have rights regarding your
            data, including the right to access, correct, or delete your data.
            As we do not collect personal information, these rights may not
            apply in the traditional sense.
          </p>
        </div>
        <div>
          <h5 className="fw-bold">6. Changes to This Privacy Policy</h5>
          <p className="mx-3">
            We may update this Privacy Policy from time to time. Any changes
            will be posted on this page with an updated effective date. We
            encourage you to review this policy periodically to stay informed
            about our privacy practices.
          </p>
        </div>
        <div>
          <h5 className="fw-bold">7. Contact Us</h5>
          <p className="mx-3">
            If you have any questions or concerns about these Terms, please
            contact us at:
          </p>
          <ul>
            <li>
              <p>
                <span className="fw-bold">DataSetsAI : </span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://datasets.ai/contact"
                  style={{ color: "#217821" }}
                >
                  Datasetsai Contact{" "}
                </a>
              </p>
            </li>
            <li>
              <p>
                <span className="fw-bold ">AntColony : </span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.antcolony.com/contact"
                  style={{ color: "#217821" }}
                >
                  AntColony Contact
                </a>
              </p>
            </li>
          </ul>
        </div>
      </Container>
      <Footer />
    </div>
  );
};
